import React from 'react'
import { Container } from 'reactstrap'
import { useStore, withStore } from '~/dataStore'
import Header from '~/components/Header'

const OpportunitiesDashboard = () => {
  const {
    app: {
      currentAdmin: { companies }
    }
  } = useStore()

  return (
    <>
      <Container fluid>
        <Header>
          <p className="mb-0 text-24 text-black">{companies?.[0].name}</p>
          <h1 className="text-black fw-bold fs-1">
            <span className="fw-bolder">Opportunities Engine</span>
          </h1>
        </Header>
      </Container>
    </>
  )
}

export default withStore(OpportunitiesDashboard)
